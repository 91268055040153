import React from "react";
import { ButtonGroup, Button } from "@mui/material";

interface YesNoQuestionProps {
  children: React.ReactNode;
  selection: boolean | null;
  onYesNoClick: (isYes: boolean) => void;
  disabled?: boolean;
}

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({
  children,
  selection,
  onYesNoClick,
  disabled,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 16,
      }}
    >
      {children}
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        disabled={disabled}
      >
        <Button
          variant={selection !== null && selection ? "contained" : "outlined"}
          onClick={() => onYesNoClick(true)}
        >
          Yes
        </Button>
        <Button
          variant={selection !== null && !selection ? "contained" : "outlined"}
          onClick={() => onYesNoClick(false)}
        >
          No
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default YesNoQuestion;

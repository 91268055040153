import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import check from "../icons/check_circle.svg";
import cancel from "../icons/cancel.svg";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import { post, get } from "../api";
import { Customer, Bill } from "../types";

const ColumnCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface AnalysisResults {
  cptCodes?: any[];
}

const Analysis: React.FC = () => {
  const { billId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const emptyAnalysisResult: AnalysisResults = {
    cptCodes: [],
  };

  const [bill, setBill] = useState<Bill | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  // Indicates if the bill upload is incomplete in which case we ask the user to enter
  // the rest of the CPT codes manually
  const [incompleteCptCodes, setIncompleteCptCodes] = useState<boolean>(false);
  const [userInputtedCptCodes, setUserInputtedCptCodes] = useState<string[]>(
    []
  );
  const [additionalCptCode, setAdditionalCptCode] = useState<string>("");

  const [messages, setMessages] = useState<any[]>([]);
  const [isLoadingChatResponse, setIsLoadingChatResponse] = useState(false);
  const [isLoadingNonChatResponse, setIsLoadingNonChatResponse] =
    useState(false);
  const [analysisResults, setAnalysisResults] =
    useState<AnalysisResults>(emptyAnalysisResult);

  // Everything in useEffect is related to hosting in an iframe
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    fetchBill();
    const postHeight = () => {
      const height = contentRef.current?.getBoundingClientRect().height;
      window.parent.postMessage(
        {
          frameHeight: height,
          type: "SET_FRAME_HEIGHT",
        },
        "https://www.nomedicaldebt.com"
      ); // Replace '*' with the parent window's origin for security
    };

    // Initialize MutationObserver and define what to observe
    const observer = new MutationObserver((mutations) => {
      postHeight();
    });

    if (contentRef.current) {
      // Start observing the contentRef element for child list changes or subtree modifications
      observer.observe(contentRef.current, {
        childList: true, // Detect direct children additions/removals
        subtree: true, // Detect all descendant additions/removals
        characterData: true, // Detect text content changes
      });
    }
    // Post the initial height
    postHeight();

    // Disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const fetchBill = async () => {
    setIsLoadingNonChatResponse(true);
    const { result, status } = await get(`/bills/${billId}`);
    if (result) {
      setBill(result.bill);
      setCustomer(result.customer);
    }

    if (result.bill.rawCptCodes.length === 0) {
      setIncompleteCptCodes(true);
    } else {
      sendMessage(null, null, null, null, result.bill);
    }
    setIsLoadingNonChatResponse(false);
  };

  const areResultsReady = () => {
    if (analysisResults.cptCodes) {
      return analysisResults.cptCodes.length > 0;
    } else {
      return false;
    }
  };

  const sendMessage = async (
    innerHtml: string | null,
    textContent: string | null,
    innerText: string | null,
    nodes: any | null,
    b?: Bill | null
  ) => {
    const body: any = {};
    const newMessages = [...messages];
    // The first time sendMessage is called to initiate the sequence, textContnet will be null
    if (textContent) {
      newMessages.push({
        id: uuidv4(),
        message: textContent,
        sentTime: Date.now() / 1000,
        sender: "You",
        direction: "outgoing",
        position: "single",
        role: "user",
      });
      setMessages(newMessages);
    }

    body["allMessages"] = newMessages;
    body["bill"] = {
      id: b?.id,
      guarantorNumber: b?.guarantorNumber,
      documentId: b?.documentId,
    };
    body["customerId"] = customer?.id;
    if (userInputtedCptCodes.length > 0) {
      body["additionalCptCodes"] = userInputtedCptCodes;
    }

    setIsLoadingChatResponse(true);
    const { result, status } = await post("chats", JSON.stringify(body));
    setIsLoadingChatResponse(false);

    setMessages(result.messages);
    if (result.results) {
      setAnalysisResults(result.results);
    }
  };

  const renderChat = () => {
    return areResultsReady() ? null : (
      <MainContainer>
        <ChatContainer>
          <MessageList
            typingIndicator={isLoadingChatResponse && <TypingIndicator />}
          >
            {messages.map(
              (message) =>
                message.role !== "system" && (
                  <Message
                    model={{
                      message: message.message,
                      sentTime: `${message.sentTime}`,
                      sender: message.sender,
                      position: message.position,
                      direction: message.direction,
                    }}
                    key={message.id}
                  >
                    <Message.Footer>
                      {message.sender} •{" "}
                      {new Date(
                        parseFloat(message.sentTime) * 1000
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Message.Footer>
                  </Message>
                )
            )}
          </MessageList>
          <MessageInput
            attachDisabled
            placeholder="Type message here"
            onSend={sendMessage}
          />
        </ChatContainer>
      </MainContainer>
    );
  };

  const renderChatContainer = () => {
    if (bill && !incompleteCptCodes) {
      return areResultsReady() ? null : (
        <div style={{ width: "100%", height: "350px" }}>{renderChat()}</div>
      );
    } else {
      return null;
    }
  };

  const renderActionContainer = () => {
    if (analysisResults.cptCodes && analysisResults.cptCodes.length > 0) {
      const propsToPass = {
        customer: customer,
        bill: bill,
        cptCodes: analysisResults.cptCodes,
      };
      const hasDisputableCptCodes = analysisResults.cptCodes?.some(
        (x) => x.meetsDefinition === false
      );
      return hasDisputableCptCodes ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() =>
              navigate("/dispute?type=self", { state: propsToPass })
            }
          >
            Generate dispute email
          </Button>
        </div>
      ) : (
        <p>
          This bill looks accurate based on the information you've provided.
        </p>
      );
    } else {
      return null;
    }
  };

  const renderProgress = () => {
    return (
      <Backdrop
        sx={{
          color: theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoadingNonChatResponse}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderCptResults = () => {
    return analysisResults.cptCodes &&
      analysisResults.cptCodes.length === 0 ? null : (
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>CPT Code</b>
                </TableCell>
                <TableCell>
                  <b>Accurate?</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analysisResults.cptCodes?.map((result) => (
                <TableRow key={result.code}>
                  <TableCell>{result.code}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {result.meetsDefinition ? (
                      <img src={check} alt="" />
                    ) : (
                      <img src={cancel} alt="" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const handleAdditionalCptCodesDone = () => {
    setIncompleteCptCodes(false);
    sendMessage(null, null, null, null, bill);
  };

  const handleCptCodeFabClicked = () => {
    setUserInputtedCptCodes([...userInputtedCptCodes, additionalCptCode]);
    setAdditionalCptCode("");
  };

  const handleCptCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalCptCode(event.target.value);
  };

  const renderRawCptCodes = (rawCptCodes: string[]) => {
    return (
      (rawCptCodes.length > 0 || incompleteCptCodes) && (
        <div>
          <h4>Identified CPT Codes</h4>
          {[...rawCptCodes, ...userInputtedCptCodes].map((code, i) => (
            <TextField
              style={{ marginBottom: 8 }}
              value={code}
              key={code}
              name={`cptCode${i}`}
              variant="outlined"
              fullWidth
              disabled
            />
          ))}
          {incompleteCptCodes && (
            <div>
              <p>
                We couldn't get all the CPT codes on your bill. You can enter
                the rest manually or proceed without them. Do NOT enter CPT
                codes that start with "8". When you're ready, tap "Done".
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{ marginBottom: 8, marginRight: 8 }}
                  name={"Additional CPT Code"}
                  variant="outlined"
                  fullWidth
                  onChange={handleCptCodeChange}
                  value={additionalCptCode}
                />
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={handleCptCodeFabClicked}
                >
                  <AddIcon />
                </Fab>
              </div>
              <Button
                style={{ marginTop: 8 }}
                variant="contained"
                fullWidth
                onClick={handleAdditionalCptCodesDone}
                disabled={
                  rawCptCodes.length === 0 && userInputtedCptCodes.length === 0
                }
              >
                Done
              </Button>
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <ColumnCenterContainer
      ref={contentRef}
      style={{
        margin: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 16,
          marginBottom: 48,
        }}
      >
        <ColumnCenterContainer>
          {bill && renderRawCptCodes(bill.rawCptCodes)}
        </ColumnCenterContainer>
        {renderChatContainer()}
        {renderCptResults()}
        {renderActionContainer()}
        {renderProgress()}
      </div>
    </ColumnCenterContainer>
  );
};

export default Analysis;

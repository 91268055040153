import React, { useState, useRef, useEffect, ReactNode } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  TextField,
  Grid,
  Container,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  DatePicker,
  LocalizationProvider,
  PickerChangeHandlerContext,
  DateValidationError,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import YesNoQuestion from "../YesNoQuestion";
import { US_STATES } from "../constants";
import { Dayjs } from "dayjs";
import { post } from "../api";

const Dispute: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const intakeProps = location.state;

  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [userSelectedPrefill, setUserSelectedPrefill] = useState<
    boolean | null
  >(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    dateOfService: "",
    guarantorNumber: intakeProps.bill.guarantorNumber,
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  // Everything in useEffect is related to hosting in an iframe
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const postHeight = () => {
      const height = contentRef.current?.getBoundingClientRect().height;
      window.parent.postMessage(
        {
          frameHeight: height,
          type: "SET_FRAME_HEIGHT",
        },
        "https://www.nomedicaldebt.com"
      ); // Replace '*' with the parent window's origin for security
    };

    // Initialize MutationObserver and define what to observe
    const observer = new MutationObserver((mutations) => {
      postHeight();
    });

    if (contentRef.current) {
      // Start observing the contentRef element for child list changes or subtree modifications
      observer.observe(contentRef.current, {
        childList: true, // Detect direct children additions/removals
        subtree: true, // Detect all descendant additions/removals
        characterData: true, // Detect text content changes
      });
    }
    // Post the initial height
    postHeight();

    // Disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const generateEmail = async () => {
    setIsLoadingResponse(true);
    const { result, status } = await post(
      "disputes",
      JSON.stringify({
        ...formData,
        type: searchParams.get("type"),
        ...intakeProps,
      })
    );
    setIsLoadingResponse(false);
    if (status === 201) {
      alert("Email sent!");
      navigate("/contribute");
      return;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDobChange = (
    newValue: Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    setFormData({
      ...formData,
      dateOfBirth: newValue ? newValue.format("YYYY-MM-DD") : "",
    });
  };

  const handleDateOfServiceChange = (
    newValue: Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    setFormData({
      ...formData,
      dateOfService: newValue ? newValue.format("YYYY-MM-DD") : "",
    });
  };

  const handleUsStateChange = (event: SelectChangeEvent, child: ReactNode) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      state: value as string,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await generateEmail();
  };

  const renderProgress = () => {
    return (
      <Backdrop
        sx={{
          color: theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoadingResponse}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const handleYesNoClick = (isTrue: boolean) => {
    setUserSelectedPrefill(isTrue);
  };

  const optionalFields = () => {
    return [
      <Grid item xs={12} sm={6}>
        <TextField
          name="firstName"
          label="First Name"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>,
      <Grid item xs={12} sm={6}>
        <TextField
          name="lastName"
          label="Last Name"
          variant="outlined"
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>,
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            // value={dayjs("01/01/2001")}
            label="Date of Birth"
            slotProps={{
              textField: {
                inputProps: { "aria-placeholder": "m m / d d / y y y y" },
              },
            }}
            onChange={handleDobChange}
          />
        </LocalizationProvider>
      </Grid>,
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            // value={dayjs("01/01/2001")}
            label="Date of appointment"
            slotProps={{
              textField: {
                inputProps: { "aria-placeholder": "m m / d d / y y y y" },
              },
            }}
            onChange={handleDateOfServiceChange}
          />
        </LocalizationProvider>
      </Grid>,
      <Grid item xs={12} sm={6}>
        <TextField
          value={formData.guarantorNumber}
          name="guarantorNumber"
          label="Guarantor Number"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>,
      <Grid item xs={12}>
        <TextField
          name="street1"
          label="Address 1"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>,
      <Grid item xs={12}>
        <TextField
          name="street2"
          label="Address 2"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>,
      <Grid item xs={12} sm={4}>
        <TextField
          name="city"
          label="City"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>,
      <Grid item xs={12} sm={4}>
        <FormControl>
          <InputLabel id="dropdown-label">State</InputLabel>
          <Select
            style={{ width: 100 }}
            labelId="dropdown-label"
            id="dropdown"
            onChange={handleUsStateChange}
          >
            {US_STATES.map((usState) => (
              <MenuItem value={usState}>{usState}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>,
      <Grid item xs={12} sm={4}>
        <TextField
          inputProps={{
            maxLength: 5,
          }}
          name="zipCode"
          label="Zip Code"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>,
    ];
  };

  return (
    <Container ref={contentRef} style={{ marginTop: 16, marginBottom: 48 }}>
      <YesNoQuestion
        selection={userSelectedPrefill}
        onYesNoClick={handleYesNoClick}
      >
        <p>
          Do you want us to prefill the email with the personal information
          you'll need?{" "}
        </p>
      </YesNoQuestion>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {userSelectedPrefill === false && (
              <p>
                Ok, we'll send you an empty template. What's your email address?
              </p>
            )}
            <TextField
              type="email"
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              required
              onChange={handleInputChange}
              style={{
                display: userSelectedPrefill !== null ? "block" : "none",
              }}
            />
          </Grid>
          {userSelectedPrefill && optionalFields().map((field) => field)}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            style={{
              marginTop: 16,
              display: userSelectedPrefill !== null ? "block" : "none",
            }}
          >
            Submit
          </Button>
        </Grid>
      </form>
      {renderProgress()}
    </Container>
  );
};

export default Dispute;

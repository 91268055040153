import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import money from "../icons/money.svg";
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
  DropEvent,
  FileRejection,
} from "react-dropzone";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { post } from "../api";
import { Customer, Bill } from "../types";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
`;

const DropzoneContainer = styled.div`
  background-color: #ececec;
  border-radius: 2;
  border-style: dashed;
  margin-bottom: 10;
  width: 100%;
  min-height: 75;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ColumnCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Intake: React.FC = () => {
  const theme = useTheme();

  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [bill, setBill] = useState<Bill | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);

  const [isLoadingFileResponse, setIsLoadingFileResponse] = useState(false);
  const [isLoadingActionButtonResponse, setIsLoadingActionButtonResponse] =
    useState(false);

  // Everything in useEffect is related to hosting in an iframe
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const postHeight = () => {
      const height = contentRef.current?.getBoundingClientRect().height;
      window.parent.postMessage(
        {
          frameHeight: height,
          type: "SET_FRAME_HEIGHT",
        },
        "https://www.nomedicaldebt.com"
      ); // Replace '*' with the parent window's origin for security
    };

    // Initialize MutationObserver and define what to observe
    const observer = new MutationObserver((mutations) => {
      postHeight();
    });

    if (contentRef.current) {
      // Start observing the contentRef element for child list changes or subtree modifications
      observer.observe(contentRef.current, {
        childList: true, // Detect direct children additions/removals
        subtree: true, // Detect all descendant additions/removals
        characterData: true, // Detect text content changes
      });
    }
    // Post the initial height
    postHeight();

    // Disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const {
    getRootProps: getRootBillFileProps,
    getInputProps: getInputBillFileProps,
    isDragActive: isBillDragActive,
  } = useDropzone({
    accept: { "application/pdf": [".pdf"], "image/jpeg": [], "image/png": [] },
    onDrop: (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      acceptedFiles.forEach((file) => {
        uploadFile(file);
      });

      if (fileRejections.length > 1) {
        alert("Please upload only one file.");
        return;
      }
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          console.log(error);
          // TODO: If allowing multiple files, take the alert out of the for loop
          alert(
            `File ${file.name} is too large. Please upload a file less than 5 MB.`
          );
        });
      });
    },
    maxSize: 5 * 1024 * 1024, // 5 MB in bytes
    multiple: false,
  });

  const uploadFile = async (file: File) => {
    setIsLoadingFileResponse(true);
    const formData = new FormData();
    formData.append("file_bill", file);
    formData.append("customerId", customer?.id || "");

    const { result, status } = await post("bills", formData);
    setIsLoadingFileResponse(false);
    if (!result) {
      return;
    }

    setCustomer(result.customer);
    if (result.bill && status !== 206) {
      const b = {
        id: result.bill.id,
        guarantorNumber: result.bill.guarantorNumber,
        file: file,
        rawCptCodes: result.bill.rawCptCodes,
        documentId: result.bill.documentId,
      };
      setBill(b);
      // Passing the bill directly b/c of race condition on async setBill
      // sendMessage(null, null, null, null, b);
    }
  };

  const parseBill = async () => {
    setIsLoadingActionButtonResponse(true);
    const { result, status } = await post(
      `bills/${bill?.id}/parse`,
      JSON.stringify({ bill: bill, email: email })
    );
    if (result.message) {
      setResponseMessage(result.message);
    }
    setIsLoadingActionButtonResponse(false);
  };

  const renderDropzone = (
    questionSuffix: string,
    rootProps: () => DropzoneRootProps,
    inputProps: () => DropzoneInputProps,
    isDragActive?: boolean
  ) => {
    return (
      <DropzoneContainer {...rootProps()}>
        <input {...inputProps()} />
        <p style={{ marginLeft: 16 }}>
          {isDragActive ? `Drop ${questionSuffix}` : `Upload ${questionSuffix}`}
        </p>
      </DropzoneContainer>
    );
  };

  const renderActionContainer = () => {
    if (responseMessage === null && bill) {
      return (
        <ActionContainer>
          <TextField
            type="email"
            fullWidth
            placeholder="you@example.com"
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: 16, minWidth: 300 }}
          ></TextField>
          <Button
            disabled={isLoadingActionButtonResponse || !bill || !email}
            variant="contained"
            onClick={parseBill}
          >
            Start
          </Button>
        </ActionContainer>
      );
    } else if (responseMessage) {
      return (
        <ActionContainer>
          <p>{responseMessage}</p>
        </ActionContainer>
      );
    } else {
      return null;
    }
  };

  const renderProgress = () => {
    return (
      <Backdrop
        sx={{
          color: theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoadingFileResponse || isLoadingActionButtonResponse}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div
      ref={contentRef}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 16,
          marginBottom: 48,
        }}
      >
        <ColumnCenterContainer>
          {!bill && (
            <p>
              We need a version of your bill that includes CPT codes. They're
              typically a sequence of 5 numbers.{" "}
              <a
                href="https://nomedicaldebt-public.s3.us-east-2.amazonaws.com/fc438fe4-bf1a-43ad-b432-d11c7d62fdc6.jpg"
                target="_blank"
                rel="noreferrer"
              >
                Here's an example.
              </a>
            </p>
          )}
          {!bill &&
            renderDropzone(
              "a picture of your medical bill here...",
              getRootBillFileProps,
              getInputBillFileProps,
              isBillDragActive
            )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {bill && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img src={money} alt="" />
                <p>{bill.file?.name}</p>
              </div>
            )}
          </div>
          {bill && (
            <p style={{ marginTop: 24 }}>
              <b>Note</b>: Parsing your bill can take a few minutes. Enter your
              email below and we'll send you a link to continue when it's ready.
            </p>
          )}
          {renderActionContainer()}
          {renderProgress()}
        </ColumnCenterContainer>
      </div>
    </div>
  );
};

export default Intake;

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Intake, Analysis, Dispute, Contribute } from "./pages/";
import { BRAND_COLOR } from "./constants";

const theme = createTheme({
  palette: {
    primary: { main: BRAND_COLOR, contrastText: "#fff" },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Intake />} />
          <Route path="/analysis/:billId" element={<Analysis />} />
          <Route path="/dispute" element={<Dispute />} />
          <Route path="/contribute" element={<Contribute />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import { API_HOST } from "./constants";

export const get = async <T extends Record<string, string>>(
  endpoint: string,
  queryParams?: T | null
) => {
  const queryStringParts: string[] = [];

  if (queryParams !== null && typeof queryParams === "object") {
    // Loop through the keys in queryParams
    Object.keys(queryParams).forEach((key) => {
      // Get the value corresponding to the key
      const value = queryParams[key];

      // Encode both the key and value and add them to the array
      queryStringParts.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });
  }

  const queryString = queryStringParts.join("&");

  const url = endpoint + (queryString ? `?${queryString}` : "");
  const response = await fetch(`${API_HOST}${url}`, {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  return await processResponse(response);
};

export const post = async (endpoint: string, data: any | null) => {
  const response = await fetch(`${API_HOST}/${endpoint}`, {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
    body: data,
  });

  return await processResponse(response);
};

const processResponse = async (response: Response) => {
  if (response.status === 500) {
    alert(
      "Something went wrong. Please email your documents to support@nomedicaldebt.com"
    );
    return { result: null, status: response.status };
  } else if (response.status === 504) {
    alert(
      "Looks like the server is working overtime! Try again in a few seconds."
    );
    return { result: null, status: response.status };
  }

  const result = await response.json();
  if (response.status === 400) {
    alert(result.message);
    return { result: null, status: response.status };
  }
  return { result, status: response.status };
};

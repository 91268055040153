import React, { useState, useRef, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "../constants";
import YesNoQuestion from "../YesNoQuestion";
import { post } from "../api";

const stripePromise = loadStripe(STRIPE_KEY);

const Contribute: React.FC = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [userWillContribute, setUserWillContribute] = useState<boolean | null>(
    null
  );

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const fetchData = async () => {
      const { result, status } = await post("contribute", JSON.stringify({}));
      setClientSecret(result.clientSecret);
    };
    fetchData();

    const postHeight = () => {
      const height = contentRef.current?.getBoundingClientRect().height;
      window.parent.postMessage(
        {
          frameHeight: height,
          type: "SET_FRAME_HEIGHT",
        },
        "https://www.nomedicaldebt.com"
      ); // Replace '*' with the parent window's origin for security
    };

    // Initialize MutationObserver and define what to observe
    const observer = new MutationObserver((mutations) => {
      postHeight();
    });

    if (contentRef.current) {
      // Start observing the contentRef element for child list changes or subtree modifications
      observer.observe(contentRef.current, {
        childList: true, // Detect direct children additions/removals
        subtree: true, // Detect all descendant additions/removals
        characterData: true, // Detect text content changes
      });
    }
    // Post the initial height
    postHeight();

    // Disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 8,
      }}
    >
      <YesNoQuestion
        selection={userWillContribute}
        onYesNoClick={(isTrue) => setUserWillContribute(isTrue)}
      >
        <p>
          Can you contribute to help keep this product free for those who can't
          afford it?
        </p>
      </YesNoQuestion>
      {userWillContribute && (
        <div id="checkout">
          {clientSecret && (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </div>
      )}
    </div>
  );
};

export default Contribute;

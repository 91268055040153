const PROD = true;

export const BRAND_COLOR = "#35b8be";
export const API_HOST = PROD
  ? "https://nomedicaldebt-e96eb0160c6f.herokuapp.com/api"
  : "http://127.0.0.1:8000/api";

export const STRIPE_KEY = PROD
  ? "pk_live_51NVJwwIbBsHz9LNRl4Cx6BUzNGYUY8pJjcFVhGDIQrGNVd19WTCmAmRy0TEgAY1COkxH5lXkc6m2Rq2Iw2npUhjT00k20A7wYT"
  : "pk_test_51NVJwwIbBsHz9LNRH541cjDsbtFPFg2Ro6iVr2msL367qRsx9jvh9e7AQO77fbXg7y50YbUzbvM7cRNkp6rgVb8h00mhxhJ0Cf";

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "DC",
];
